<template>
	<div class="dropdown">
		<BaseButton
			:title="title || text"
			:class="['dropdown-toggle', {'is-active': active}]"
			:click="_onClick"
		>
			{{ text }}
		</BaseButton>

		<transition name="dropdown">
			<ul
				v-if="active"
				v-on-clickaway="_onClose"
				class="dropdown-menu"
			>
				<slot />
			</ul>
		</transition>
	</div>
</template>

<script>
import {mixin as clickaway} from 'vue-clickaway';


export default {
	name: 'BaseDropdown',
	mixins: [clickaway],
	props: {
		text: {
			type: String,
			required: true
		},
		clickaway: {
			type: Boolean,
			default: true
		},
		title: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			active: false
		};
	},
	methods: {
		_onClick() {
			this.active = !this.active;
		},
		_onClose() {
			if (this.clickaway) {
				this.active = false;
			}
		}
	}
};
</script>

<style lang="scss">
	.dropdown {
		position: relative;

		.button {
			justify-content: flex-start;
			margin: 0;
			width: 100%;
			text-align: left;
			text-transform: none;

			&:not(.dropdown-toggle) {
				border-radius: 0;
			}

			&:hover,
			&:focus {
				background-color: $color-light;
				color: $color-text;
			}
		}

		ul,
		li {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		&-toggle {
			position: relative;
			background-color: $color-light;

			&.is-active {
				background-color: $color-border;
				color: $color-text;

				&::after {
					margin-top: 2px;
					border-width: 2px 0 0 2px;
				}
			}

			&::after {
				transition: transform $transition-duration ease-out;
				content: '';
				transform: translate(0, -50%) rotate(45deg);
				position: absolute;
				top: 50%;
				right: 20px;
				z-index: 0;
				margin-top: -2px;
				padding: 3px;
				border-width: 0 2px 2px 0;
				border-style: solid;
			}
		}

		&-menu {
			overflow: auto;
			transform: translateY(0.5rem);
			position: absolute;
			z-index: 9;
			padding: 0.5rem 0;
			min-width: 100%;
			max-height: 300px;
			background: $color-white;
			border: 1px solid $color-border;
			border-radius: 3px;
			box-shadow: 2px 2px 0.2rem rgba($color-black, 0.1);
		}
	}

	.dropdown-enter-active,
	.dropdown-leave-active {
		transition: all $transition-duration ease-out;
	}

	.dropdown-enter,
	.dropdown-leave-to {
		opacity: 0;
		transform: scale(0.85);
	}
</style>
